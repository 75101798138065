/**
 * @file ClassName library.
 * @author Andrei Ozdion <a.ozdion@kouch.tv>
 * @copyright Kouch Entertainment 2019
 */

export class ClassName {
	constructor(
		private readonly _name: string,
		protected $module: CSSModule | null = null
	) {}

	public static create(name: string, module: CSSModule | null = null): ClassName {
		return new ClassName(name, module);
	}

	public static join(
		...names: (string | boolean | null | undefined | (string | boolean | null | undefined)[])[]
	): string {
		return (Array.isArray(names[0]) ? names[0] : names).filter(Boolean).join(' ');
	}

	public get(...args: (string | null | undefined | (string | boolean | null | undefined)[])[]): string {
		const chain = args.filter((arg) => typeof arg === 'string');
		const base = [this._name, ...chain].join('__');

		const modifiers = Array.isArray(args[args.length - 1])
			? Array.from(args[args.length - 1] as (string | boolean)[]).filter(Boolean)
			: [];

		return (this.constructor as typeof ClassName).join(
			[base, ...modifiers.map((modifier) => `${base}__m-${modifier}`)].map((name) =>
				!!this.$module ? this.$module[name] : name
			) as string[]
		);
	}
}

export default ClassName;
