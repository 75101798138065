/**
 * @file API library.
 * @author Andrei Ozdion <a.ozdion@kouch.tv>
 * @copyright Kouch Entertainment 2024
 */

import _defaults from 'lodash/defaults';

import _isString from 'lodash/isString';
import _isNumber from 'lodash/isNumber';
import _isBoolean from 'lodash/isBoolean';
import _isNil from 'lodash/isNil';

export type TRequestMethod = 'get' | 'GET' | 'post' | 'POST';

export type TRequestParameterValuePrimitive = string | number | boolean | null | undefined;

export type TRequestParameterValue =
	| TRequestParameterValuePrimitive
	| (TRequestParameterValuePrimitive | TRequestParameters)[]
	| TRequestParameters;

export type TRequestParameters = {
	[key: string]: TRequestParameterValue;
};

export interface IRequestOptions {
	baseUrl: string;
	method: TRequestMethod;
	parameters: TRequestParameters;
}

export class API {
	public static BASE_URL: string = `https://${[API_HOSTNAME, PREFIX].filter(Boolean).join('/')}/twirp`;

	public get baseUrl(): string {
		return this.$baseUrl || API.BASE_URL;
	}

	public constructor(protected readonly $baseUrl: string = '') {}

	public async request<EXPECTED = any>(path: string, options?: Partial<IRequestOptions>): Promise<EXPECTED> {
		const _options: IRequestOptions = _defaults(options, {
			baseUrl: this.baseUrl,
			method: 'POST',
			parameters: {},
		});

		const url = [_options.baseUrl.trim().replace(/\/$/, ''), path.trim().replace(/^\//, '')].join('/');

		const isGetRequest = _options.method.toUpperCase() === 'GET';

		return fetch(
			[
				url,
				isGetRequest &&
					Object.entries(_options.parameters)
						.reduce(
							(result, [key, value]) => [
								...result,
								[
									encodeURIComponent(key),
									API.$isPrimitiveValue(value)
										? encodeURIComponent(String(value))
										: Array.isArray(value)
											? encodeURIComponent(value.join(','))
											: encodeURIComponent(JSON.stringify(value)),
								].join('='),
							],
							[] as string[]
						)
						.join('&'),
			]
				.filter(Boolean)
				.join('?'),
			{
				method: _options.method.toUpperCase(),
				mode: 'cors',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				...(!isGetRequest ? { body: JSON.stringify(_options.parameters) } : {}),
			}
		).then((response) => response.json());
	}

	protected static $isPrimitiveValue(value: TRequestParameterValue) {
		return [_isString(value), _isNumber(value), _isBoolean(value), _isNil(value)].some((check) => !!check);
	}
}
